import { doc, getDoc, where } from "firebase/firestore";
import { db } from "../components/firebase";
import { ExerciseUpd, enExerciseTranslation } from "../components/ExerciseUpd";
import { normalizeString } from "../utils/stringUtils";
// fetchExercise.ts
import { collection, getDocs, query, orderBy, limit, startAfter, Query, DocumentData } from "firebase/firestore";

interface FetchExercisesResponse {
  exercises: ExerciseUpd[];
  lastDoc: DocumentData | null;
  hasMore: boolean;
}

export const fetchPaginatedExercises = async (
  lastDoc: DocumentData | null = null,
  pageSize: number = 9,
  bodyParts?: string[],
  searchString?: string,
  language: string = 'en'
): Promise<FetchExercisesResponse> => {
  try {
    const exercisesRef = collection(db, 'exercises_upd');
    let q: Query = query(
      exercisesRef,
      where('created_by', '==', 'ht0J8E9RuxS1zT8UKd2cORf5Ea72'),
      where("is_active", "==", true) // Add this line to filter active exercises
    );

    if (bodyParts && bodyParts.length > 0) {
      q = query(
        q,
        where(
          `filter_fields.${language}.body_parts`,
          "array-contains-any",
          bodyParts
        )
      );
    }

    if (searchString) {
      const normalizedSearchString = normalizeString(searchString);
      q = query(
        q,
        where(
          `filter_fields.${language}.normalized_title`,
          ">=",
          normalizedSearchString
        ),
        where(
          `filter_fields.${language}.normalized_title`,
          "<=",
          normalizedSearchString + "\uf8ff"
        )
      );
    }

    if (lastDoc) {
      q = query(q, startAfter(lastDoc), limit(pageSize));
    } else {
      q = query(q, limit(pageSize));
    }

    const querySnapshot = await getDocs(q);
    const exerciseIds = querySnapshot.docs.map(doc => doc.id);
    const exercisesData = await fetchExercisesByIds(exerciseIds);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const hasMore = querySnapshot.docs.length === pageSize;

    return {
      exercises: exercisesData,
      lastDoc: lastVisible || null,
      hasMore
    };
  } catch (error) {
    console.error('Error fetching paginated exercises:', error);
    return {
      exercises: [],
      lastDoc: null,
      hasMore: false
    };
  }
};
export const fetchExercisesByIds = async (exerciseIds: string[]): Promise<ExerciseUpd[]> => {
  try {
    const exercisePromises = exerciseIds.map(async (id) => {
      try {
        // Get main exercise document
        const exerciseRef = doc(db, 'exercises_upd', id);
        const exerciseSnap = await getDoc(exerciseRef);
        
        if (!exerciseSnap.exists()) {
          console.warn(`Exercise ${id} not found`);
          return null;
        }

        const exerciseData = exerciseSnap.data();

        // Get translations document
        const translationsRef = doc(exerciseRef, 'translations', 'en');
        const translationsSnap = await getDoc(translationsRef);
        
        const translations = translationsSnap.exists() ? 
          translationsSnap.data() as enExerciseTranslation : 
          null;

        return {
          id: exerciseSnap.id,
          countdown: exerciseData.countdown,
          created_by: exerciseData.created_by,
          repeats: exerciseData.repeats,
          video_URL: exerciseData.video_URL,
          thumbnail_URL: exerciseData.thumbnail_URL,
          translations: translations
        } as ExerciseUpd;

      } catch (error) {
        console.error(`Error fetching exercise ${id}:`, error);
        return null;
      }
    });

    const exercises = await Promise.all(exercisePromises);
    
    // Filter out null values from failed fetches
    return exercises.filter((exercise): exercise is ExerciseUpd => exercise !== null);

  } catch (error) {
    console.error('Error in bulk exercise fetch:', error);
    return [];
  }
};